:root {
	--white: #ffffff;
	--blue: #125e9f;
	--blue2: #5276a0;
	--blue3: #6c87c6;
	--blue4: #6184d6;
	--grey: #5f5f5f;
	--grey2: #f2f0f4;
	--green: #79b256;
	--purple: #6e61b4;
	--bigFontSize: 40px;
	--fontFamily: Helvetica Neue, serif;
	--header-height: 56px;
	--filter-panel-close-height: 90px;
	--content-width: 100%;

	@media screen and (min-width: 768px) {
		--content-width: 1080px;
	}
}

body {
	font-family: "Roboto", serif;
	font-size: 16px;
}
